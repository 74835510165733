'use client';
import { Fragment } from "react";
import { useReducer } from "react";

// import eyeball images
import { ReactComponent as EyeOpen } from '@/public/svgs/eye-open.svg';
import { ReactComponent as EyeClosed } from '@/public/svgs/eye-closed.svg';

type InlineInput = React.InputHTMLAttributes<HTMLInputElement> & {
    error?: string;
    isPassword?: boolean;
    onBlur?: (evt: React.ChangeEvent) => void;
    onChange?: (evt: React.ChangeEvent) => void;
    onClick?: (evt: React.MouseEvent) => void;
    onFocus?: (evt: React.ChangeEvent) => void;
    value?: string;
};

function showPasswordReducer(state: boolean, action: { type: 'show' | 'hide'; })
{
    switch (action.type)
    {
        case 'show':
            return true;
        case 'hide':
            return false;
        default:
            throw new Error();
    }
}

export default function InlineInput({
    error,
    isPassword,
    onBlur,
    onChange,
    onClick,
    onFocus,
    value,
    ...props
}: InlineInput)
{

    const [showPassword, dispatchShowPassword] = useReducer(showPasswordReducer, false);

    return (
        <div className="inline-input">
            { !isPassword
                ? (
                    <Fragment>
                        <input
                            className={ `default ${ error ? 'has-error' : '' }` }
                            disabled={ props.disabled }
                            type={ props.type }
                            name={ props.name }
                            id={ props.id }
                            defaultValue={ value }
                            placeholder={ props.placeholder }
                            onChange={ onChange }
                            onClick={ onClick }
                            onBlur={ onBlur }
                            onFocus={ onFocus } />

                        { error &&
                            <p className="error">{ error }</p>
                        }
                    </Fragment>
                )
                : (
                    <Fragment>
                        <input
                            className={ `password ${ error ? 'has-error' : '' }` }
                            disabled={ props.disabled }
                            autoComplete="on"
                            type={ showPassword ? 'text' : props.type }
                            name={ props.name }
                            id={ props.id }
                            defaultValue={ value }
                            placeholder={ props.placeholder }
                            onChange={ onChange }
                            onClick={ onClick }
                            onBlur={ onBlur }
                            onFocus={ onFocus } />

                        <button
                            type="button"
                            className={ `show-password `.concat(
                                error
                                    ? ' has-error'
                                    : '',
                            ) }
                            onClick={ () =>
                            {
                                if (showPassword)
                                    dispatchShowPassword({ type: 'hide' });
                                else
                                    dispatchShowPassword({ type: 'show' });
                            } }
                        >
                            { showPassword
                                ? <EyeClosed />
                                : <EyeOpen />
                            }
                        </button>

                        { error &&
                            <p className="error">{ error }</p>
                        }
                    </Fragment>
                )
            }
        </div>
    );
}