"use client";
import { Fragment, useContext } from "react";
import Link from "next/link";
import { useEffect, useState, useRef, useCallback } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { Provider } from "@supabase/supabase-js";
import { SpinnerCircularFixed } from "spinners-react";
import { useRouter } from "next/navigation";
import { RequestError } from "@/utility/custom-error";
import { LenisContext } from "@/context/lenis-context";
import { v4 as uuidv4 } from "uuid";

// components
import InlineInput from "../inputs/inline-input";

// import media
import { ReactComponent as GoogleLogo } from "@/public/svgs/google-logo.svg";
import { ReactComponent as LinkedinLogo } from "@/public/svgs/linkedin-logo.svg";
import { ReactComponent as AppleLogo } from "@/public/svgs/apple-logo.svg";
import { ReactComponent as FacebookLogo } from '@/public/svgs/facebook-logo.svg';
import CustomWait from "@/utility/custom-wait";

const validationSchema = yup.object().shape(
  {
    email: yup.string()
      .email("Please enter a valid email")
      .required("Email is required."),
    password: yup.string()
      .min(6, "Password must be at least 6 characters.")
      .required("Password is required."),
  },
);

export default function ClientForm({
  type,
}: {
  type: "login" | "signup";
})
{
  // router
  const router = useRouter();

  // context
  const lenisContext = useContext(LenisContext);

  // state
  const [isLoading, setIsLoading] = useState<{ loading: boolean, message: string; }>({
    loading: false,
    message: ''
  });
  const [signupComplete, setSignupComplete] = useState<boolean>(false);

  // supabase
  const supabase = createClientComponentClient();

  // refs
  const isMountedRef = useRef<boolean>(false);

  useEffect(() =>
  {
    isMountedRef.current = true;

    return () =>
    {
      isMountedRef.current = false;
    };

  }, []);

  const handleSubmit = useCallback((values: { email: string; password: string; }) =>
  {
    switch (type)
    {
      case "login":
        HandleSignInWithEmail(values);
        break;
      case "signup":
        HandleSignUpWithEmail(values);
        break;
    }
  }, []);


  const HandleOAuthLogin = useCallback(async (provider: Provider) =>
  {
    if (provider === 'linkedin_oidc')
      setIsLoading({
        loading: true, message: `
    First time logging in with LinKedin?<br>
    Please wait while we set up your account.
      `
      });

    await supabase.auth.signInWithOAuth({
      provider,
      options:
      {
        redirectTo: `${ location.origin }/auth/callback`,
      }
    });
  }, []);

  const HandleSignInWithEmail = async (
    values: { email: string; password: string; },
  ) =>
  {
    setIsLoading({ loading: true, message: 'Logging in...' });

    try
    {
      const response = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password,
      });

      // redirect
      if (response.data.user)
        router.push(`${ location.origin }`);
      else
        throw new RequestError("User not found", 404);

    } catch (err: any)
    {
      if (err instanceof RequestError)
      {
        lenisContext.addToast({
          id: uuidv4(),
          message: err.message,
          type: 'error',
          statusCode: err.statusCode
        });
      }

      isMountedRef.current && setIsLoading({ loading: false, message: '' });
    }
  };

  const HandleSignUpWithEmail = async (
    values: { email: string; password: string; },
  ) =>
  {

    try
    {
      const data = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
        options: {
          emailRedirectTo: `${ location.origin }/login`
        }
      });

      // if error throw data and access error in catch block
      if (data.error)
        throw data;

      isMountedRef.current && setSignupComplete(true);

    } catch (err: any)
    {
      if (err.error)
      {
        lenisContext.addToast({
          id: uuidv4(),
          message: 'User with this email already exists.',
          type: 'error',
          statusCode: 409
        });
      }
      else
      {
        lenisContext.addToast({
          id: uuidv4(),
          message: 'Unknown sign up error occurred, please try again later.',
          type: 'error',
          statusCode: 500
        });
      }
    }
  };

  return (
    <Formik
      initialValues={ {
        email: "",
        password: "",
      } }
      validationSchema={ validationSchema }
      enableReinitialize
      onSubmit={ (values) => handleSubmit(values) }
      validateOnBlur
    >
      { ({ values, errors, touched, handleChange, handleBlur }) =>
      {
        return (
          <div className="login-form">

            <Form>

              { isLoading.loading &&
                <div className="loader">
                  <div className="wrapper">
                    <p dangerouslySetInnerHTML={ { __html: isLoading.message } }>
                    </p>
                    <SpinnerCircularFixed />
                  </div>
                </div>
              }

              {/* Email field */ }
              { false &&
                <>
                  <InlineInput
                    disabled={ signupComplete }
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={ values.email }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    error={ touched.email ? errors.email : "" }
                  />

                  <InlineInput
                    disabled={ signupComplete }
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={ values.password }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    error={ touched.password ? errors.password : "" }
                    isPassword />

                  <button
                    disabled={ signupComplete }
                    className="big-btn -black"
                    type="submit">
                    { type === "login" ? "Log In" : "Sign Up" }
                  </button>

                  { signupComplete &&
                    <p style={ { marginBottom: '3.2rem', textAlign: 'center' } } className="text-success">
                      Sign up completed. Please check your email to verify your account!
                    </p>
                  }


                  { type === "login"
                    ? (
                      <Fragment>
                        <Link
                          href="/signup"
                          className="form-prompt">
                          New User? <span>Create an account</span>
                        </Link>
                        <Link
                          href="/forgot-password"
                          className="form-prompt">
                          Forgot your password? <span>Reset it here</span>
                        </Link>
                      </Fragment>

                    )
                    : (
                      <Fragment>
                        <Link
                          href="/login"
                          className="form-prompt">
                          Already have an account? <span>Back to login</span>
                        </Link>
                        <Link
                          href="/forgot-password"
                          className="form-prompt">
                          Forgot your password? <span>Reset it here</span>
                        </Link>
                      </Fragment>
                    ) }

                  <div className="separator">
                    <span></span>
                    <p>or</p>
                    <span></span>
                  </div>
                </>

              }

              {/* Google */ }
              <button
                type="button"
                className="logo-btn -rounded"
                onClick={ () => HandleOAuthLogin('google') }>
                <div className="logo">
                  <GoogleLogo />
                </div>
                Continue with Google
              </button>

              {/* Facebook */ }
              {/* <button
              type="button"
              className="logo-btn -rounded"
              onClick={ () => HandleOAuthLogin('facebook') }>
              <div className="logo">
                <FacebookLogo />
              </div>
              Continue with Facebook
            </button> */}

              {/* Linkedin */ }
              <button
                type="button"
                className="logo-btn -rounded"
                onClick={ () => HandleOAuthLogin('linkedin_oidc') }>
                <div className="logo">
                  <LinkedinLogo />
                </div>
                Continue with Linkedin
              </button>

              {/* Apple */ }
              {
                /* <button
                                  type="button"
                                  className="logo-btn -rounded -blacksvg">
                                  <div className="logo">
                                      <AppleLogo />
                                  </div>
                                  Continue with Apple
                              </button> */
              }

            </Form>

            <p className="project-version">
              Version 1.0
            </p>
          </div>
        );
      } }
    </Formik>
  );
};
