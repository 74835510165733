export class RequestError extends Error
{
    statusCode: number;

    constructor(message: string, code: number)
    {
        super(message);
        this.name = this.constructor.name;
        this.statusCode = code;
    }
}